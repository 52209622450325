/* ======================================================================== */
/* 80. sectionVideo */
/* ======================================================================== */
.section-video
	display: flex
	align-items: center
	justify-content: center
	width: 100%
.section-video__link
	position: relative
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	width: 100%
	z-index: 100
	&:hover
		border-color: $sandstone
		color: $sandstone
	&:focus
		opacity: 1
.section-video__icon
	position: relative
	display: inline-flex !important
	align-items: center
	justify-content: center
	width: 160px
	height: 160px
	border-radius: 100%
	border: 2px solid $borders-dark
	+small-caps
	font-weight: bold
	color: $white
	+trans1
	&:hover
		color: $sandstone
	svg
		display: block
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		margin: auto
		width: 160px
		height: 160px
		border-radius: 100%
		stroke-width: 1px
		z-index: 50
		.circle
			stroke: $sandstone
.section-video__overlay
	z-index: 0

@media screen and (max-width: $sm)
	.section-video__icon
		width: 120px
		height: 120px
		svg
			width: 120px
			height: 120px
