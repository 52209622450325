/* ======================================================================== */
/* 96. widget */
/* ======================================================================== */
.widget
	p
		&:last-of-type
			margin-bottom: 0
	select
		width: 100%
		max-width: 100%
	ul
		+reset-ul
		text-align: left
		li
			display: block
			margin-bottom: 20px
			&:last-child
				margin-bottom: 0
		ul
			width: 100%
			padding-left: 1em
			margin-top: 1em
		.sub-menu
			padding-left: 1em
			margin-top: 1em
	ul
		li
			a
				color: $brown-grey
				&:hover
					color: $sandstone

.widgettitle
	display: block
	+small-caps
	color: $dusk
	margin-bottom: 1.5em
	&:before
		content: ''
		display: block
		width: 100%
		height: 2px
		background-color: $dusk
		margin-bottom: 0.5em
