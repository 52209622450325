/* ======================================================================== */
/* 74. sectionSliderThemes */
/* ======================================================================== */
.section-slider
	/////////////////////////////////////////////
	&.bg-dark-3
		.slider__counter_current
			color: $sandstone
		.slider__dot
			.circle
				stroke: $sandstone
	/////////////////////////////////////////////
	&.bg-accent-primary-1
		.slider__dot
			border-color: $borders-light
			.circle
				stroke: $white
		.slider__arrow
			color: $white
		.slider__arrows-divider, .slider__counter-divider
			background-color: $borders-light
		.slider__counter_current
			color: $white
		.slider__counter_total
			color: $dark
	/////////////////////////////////////////////
	&.bg-accent-primary-2
		.slider__counter_current
			color: $sandstone
		.slider__dot
			.circle
				stroke: $sandstone
	/////////////////////////////////////////////
	&.bg-accent-primary-3
		.slider__dot
			.circle
				stroke: $white
		.slider__arrow
			color: $white
		.slider__counter_current
			color: $white
		.slider__counter_total
			color: $dark
	/////////////////////////////////////////////
	&.bg-accent-primary-4
		.slider__dot
			border-color: $borders-light
			.circle
				stroke: $white
		.slider__arrow
			color: $white
		.slider__arrows-divider, .slider__counter-divider
			background-color: $borders-light
		.slider__counter_current
			color: $white
		.slider__counter_total
			color: $dark
	/////////////////////////////////////////////
	&.bg-accent-secondary-1
		.slider__dot
			border-color: $borders-light
			.circle
				stroke: $white
		.slider__arrow
			color: $white
		.slider__arrows-divider, .slider__counter-divider
			background-color: $borders-light
		.slider__counter_current
			color: $white
		.slider__counter_total
			color: $dark				
	/////////////////////////////////////////////
	&.bg-accent-secondary-3
		.slider__counter_current
			color: $sandstone
		.slider__dot
			.circle
				stroke: $sandstone

	/////////////////////////////////////////////
	&.bg-accent-secondary-4
		.slider__counter_current
			color: $sandstone
		.slider__dot
			.circle
				stroke: $sandstone
