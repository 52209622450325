/* ======================================================================== */
/* 87. sliderLetters */
/* ======================================================================== */
.slider-letters
	width: 100%
	height: 100%
.vector-letter:not(:first-child)
	visibility: hidden
svg.vector-letters
	height: 130px
