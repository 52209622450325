/* ======================================================================== */
/* 55. sectionAwards */
/* ======================================================================== */
.section-awards__wrapper-item
	&:first-child
		.figure-award
			padding-top: 0
	&:last-child
		.figure-award
			padding-bottom: 0
		.figure-award__border-line
			display: none
