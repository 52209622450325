/* ======================================================================== */
/* 100. widgetCassioMenuInline */
/* ======================================================================== */
.widget_cassio_menu_inline
	ul
		li
			display: inline-block
			margin-bottom: 0
			a
				padding: 4px
				&:before
					display: none
					
@media screen and (max-width: $md)
	.widget_cassio_menu_inline
		.menu
			text-align: center
