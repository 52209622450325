/* ======================================================================== */
/* 81. slider */
/* ======================================================================== */
.slider:not(.container)
	max-width: 100%
.slider_offset-top
	margin-top: -200px
	padding-bottom: 200px
.slider__bg
	width: 100%
	height: 100%
	background-position: center center
	background-size: cover
	background-repeat: no-repeat
.slider__images-slide-inner
	transition: transform 2.4s ease
	transform: scale(1.05)
	width: 100%
	height: 100%
.slider__images-slide
	overflow: hidden
	&.swiper-slide-active
		.slider__images-slide-inner
			transform: scale(1)
	.swiper-slide
		&:not(.swiper-slide-active):not(.swiper-slide-prev):not(.swiper-slide-next)
			.slider__images-slide-inner
				display: none !important
.slider__counter
	+small-caps
	height: 24px
.slider__counter_current
	color: $pinkish-grey
.slider__counter_total
	color: $brown-grey
.slider__link
	display: block
.slider__progress
	display: inline-flex
	align-items: center
.slider__progressbar
	position: relative
	width: 180px
	height: 1px
	background: $brown-grey
.slider__progressbar-fill
	position: absolute
	top: 0
	left: 0
	bottom: 0
	width: 100%
	background-color: $dark
	transform-origin: left center
	+trans1

.slider__dot
	position: relative
	width: 14px
	height: 14px
	margin: 0 8px
	cursor: pointer
	outline: none
	display: inline-block
	border: 2px solid $borders-dark
	border-radius: 100%
	vertical-align: middle
	z-index: 50
	svg
		display: block
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		margin: auto
		width: 14px
		height: 14px
		border-radius: 100%
		stroke-width: 42px
		z-index: 50
		.circle
			stroke: $sandstone
.slider__dots
	display: inline-flex
	height: 24px
.slider__arrows
	display: inline-flex
	align-items: center
.slider__arrows-divider, .slider__counter-divider
	width: 1px
	height: 18px
	background-color: $borders-dark
	transform: rotate(45deg)
	margin-left: 40px
	margin-right: 40px

.slider__arrow
	position: relative
	display: inline-flex
	align-items: center
	justify-content: center
	cursor: pointer
	+trans1
	outline: none
	width: 24px
	height: 24px
	color: $brown-grey
	i
		font-size: 24px
	&.swiper-button-disabled
		opacity: 0.3
		color: $brown-grey
	&:hover
		color: $pinkish-grey
.slider__footer
	position: absolute
	bottom: 0
	left: 0
	right: 0
	padding-bottom: 80px
	padding-left: 0
	padding-right: 0
	z-index: 50
.slide__arrow_prev_centered
	position: absolute
	top: 50%
	left: 0
	padding-left: 120px
	transform: translateY(-50%)
	z-index: 50
.slide__arrow_next_centered
	position: absolute
	top: 50%
	right: 0
	padding-right: 120px
	transform: translateY(-50%)
	z-index: 50
.slider__footer_half
	width: 50%
.slider__footer_left
	margin-right: auto
.slider__footer_right
	margin-left: auto
.swiper-lazy
	opacity: 0
	visibility: hidden
	transition: opacity 0.6s ease
.swiper-lazy-loaded
	opacity: 1
	visibility: visible
.slider__footer-relative
	padding-top: 30px
.slider__overlay
	z-index: 1
.slider__overlay_solid
	opacity: 1
	mix-blend-mode: normal
.slider__progress
	vertical-align: top

@media screen and (max-width: $xxl)
	.slider__footer
		padding-bottom: 60px
	.slide__arrow_prev_centered
		padding-left: 80px
	.slide__arrow_next_centered
		padding-right: 80px

@media screen and (max-width: $xl)
	.slider__footer
		padding-bottom: 40px
	.slide__arrow_prev_centered
		padding-left: 50px
	.slide__arrow_next_centered
		padding-right: 50px

@media screen and (max-height: 800px) and (min-width: $md)
	.slider__footer
		padding-bottom: 40px

@media screen and (max-width: $md)
	.slider__footer
		padding-bottom: 20px
	.slider__footer_halfscreen
		padding-top: 20px
		padding-bottom: 0
		position: relative
		top: auto
		left: auto
		right: auto
		bottom: auto
	.slide__arrow_prev_centered
		padding-left: 30px
	.slide__arrow_next_centered
		padding-right: 30px
	.slider-halfscreen__container
		padding-left: 0
		padding-right: 0

@media screen and (max-width: $sm)
	.slider__footer-relative
		padding-top: 20px 
	.slider__footer-col
		padding-top: 10px
		&.text-left, &.text-center, &.text-right
			text-align: center !important
	.slider__arrows-divider, .slider__counter-divider
		margin-left: 20px
		margin-right: 20px
	.slider__counter
		height: 18px
	.slider__arrow
		width: 18px
		height: 18px
		i
			font-size: 18px
	.slider__progress, .slider__arrows, .slider__dots
		vertical-align: top
	.slider__dots
		height: 18px
