/* ======================================================================== */
/* 94. typographyAccentSecondaryAdjustments */
/* ======================================================================== */
.bg-accent-secondary-1
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pale
	.section__headline
		background-color: $pale
// /////////////////////////////////////////////
.bg-accent-secondary-2
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pinkish-grey
// /////////////////////////////////////////////
.bg-accent-secondary-3
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pinkish-grey
// /////////////////////////////////////////////
.bg-accent-secondary-4
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pinkish-grey
