/* ======================================================================== */
/* 88. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials__text
	font-weight: 300
	font-style: italic
	margin-top: 0
.slider-testimonials__author
	display: flex
	align-items: center
	+small-caps
	color: $pinkish-grey
	margin-top: 3em
.slider-testimonials__author-line
	width: 60px
	height: 1px
	background-color: $sandstone
	margin-right: 2em
.slider-testimonials__footer
	margin-top: 30px
